/* Default settings for app */

:root{
  /* Font slova i font family */
  --clr-primary: red;
  
  --clr-neutral: blue;

  --ff-primary: sans-serif;
  --ff-secondary: serif;

}

html,body{
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

*,
*::before,
*::after {
box-sizing: border-box;
}

html {
font-size: 1rem;
min-width: 320px;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
margin: 0;
}

img {
display: block;
max-width: 100%;
object-fit: cover;
}

.is-loading{
  display: none;
}


/* Tablet mode */

@media screen and (min-width: 767px){
  html{
    font-size: 1.25rem;
  }
}

@media screen and (min-width: 1365px){
  html{
    font-size: 1.3rem;
  }
}

@media screen and (min-width: 1919px) {
  html{
    font-size: 1.7rem;
  }
}