.contact-container{
    background: #171717;
    padding-top: 3rem;
    padding-bottom: 3rem;
    text-align: center;
    position: relative;
    height: 100vh;
    width: 100%;
}

/* Contact form */

.contact-form{
    justify-content: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.contact-title{
    color: #ededed;
    font-family: sans-serif;
    font-size: 2rem;
    padding-bottom: 1rem;
}

.contact-text{
    color: #444;
    line-height: 1.4rem;
    padding-bottom: 3rem;
    text-align: center;
}

.contact-button{
    color: #da0037;
    border: 2px solid #da0037;
    font-family: sans-serif;
    border-radius: .4rem;
    padding: .8rem 1.3rem;
    margin: 0 auto;
    text-decoration: none;
}

/* .contact-button:hover{
    cursor: pointer;
    padding: 1.2rem 1.7rem;
    transition: .5s ease-in-out;
} */
.contact-button:hover,
.contact-button:focus{
    cursor: pointer;
    box-shadow: inset 0 0 0 2em #da0037;
    transition: all .5s ease-in-out;
    color: #ededed;
}

/* Tablet mode */

@media screen and (min-width: 767px){
    .contact-text{
        max-width: 450px;
        text-align: center;
        margin: 0 auto;
    }
}

@media screen and (min-width: 1919px){
    .contact-text{
        max-width: 650px;
    }
}